body {
  margin: 0;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.mantine-Popover-dropdown {
  z-index: 10000 !important
}